import React from "react";
import HPELogo from "../assets/images/hpe_aruba_networking_logo.svg";

function TopMenu() {
  return (
    <div className="top-menu-container">
      <img src={HPELogo} alt="HPE Aruba Networking" className="App-logo" />
      <div className="app-title">HPE Networking API Services</div>
    </div>
  );
}
export default TopMenu;
