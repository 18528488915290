import React from "react";
import { RedocStandalone } from "redoc";
import data from "./data/krakend_swagger.json";

function ApiDefinitions() {
  return (
    <RedocStandalone
      spec={data}
      onLoaded={() => {
        var aTags = document.getElementsByTagName("a");
        var searchText = "API docs by Redocly";
        var found;

        for (var i = 0; i < aTags.length; i++) {
          if (aTags[i].textContent == searchText) {
            found = aTags[i];
            break;
          }
        }

        found.style.display = "none";
      }}
    />
  );
}
export default ApiDefinitions;
