import React from "react";
import logo from "./logo.svg";
import "./App.css";
import TopMenu from "./common/TopMenu";
import ApiDefinitions from "./components/APIDefinitions";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <TopMenu />
      </header>
      <div>
        <ApiDefinitions />
      </div>
    </div>
  );
}

export default App;
